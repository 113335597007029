<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <translatable-input
              v-model="form.name"
              label="SKU name"
              class="mt-5"
            />

            <v-text-field
              v-model="form.stock"
              label="Stock"
              suffix="pcs/ppl"
              :rules="[between(form.stock, 0, 99999999)]"
              :disabled="disabledFields.includes('stock')"
            ></v-text-field>

            <v-text-field
              v-model="form.stock_threshold"
              label="Low Stock Threshold"
              suffix="pcs/ppl"
              :rules="[between(form.stock_threshold, 1, 99999999)]"
              :disabled="disabledFields.includes('stock_threshold')"
              hint="combine with merchant setting + template, notify when stock below threshold"
            ></v-text-field>

            <p v-if="disabledFields.includes('stock')">
              <span v-if="product.nature === 'voucher'">For voucher sku, stock value is controlled by voucher count.</span>
              <span v-if="product.nature === 'plan'">For plan sku, stock is unlimited.</span>
            </p>

            <v-text-field
              v-model="form.merchant_sku_id"
              label="Custom SKU ID"
            ></v-text-field>

            <v-autocomplete
              v-model="form.branch_id"
              :items="branchOptions"
              item-text="title"
              item-value="value"
              label="Main Branch (optional) for display."
              clearable
              outlined
              chips
              hide-details
            ></v-autocomplete>

            <div
              v-if="product.nature === 'topup'"
              class="mt-4 bordered"
            >
              <v-text-field
                v-model="searchQuery"
                label="Search Recharge Target (name.en) for Quota Rule"
              >
              </v-text-field>

              <v-autocomplete
                v-model="form.quota_rule_id"
                :items="quotaRuleOptions"
                item-text="title"
                item-value="value"
                label="Quota Rule (Empty if not topup)"
                hide-details
              ></v-autocomplete>
            </div>

            <div
              v-if="product.nature === 'activity'"
              class="mt-4 bordered"
            >
              <v-autocomplete
                v-model="form.activity_rule_id"
                :items="activityRuleOptions"
                item-text="label"
                item-value="value"
                label="Activity Rule"
                clearable
                hide-details
              ></v-autocomplete>
            </div>

            <v-switch
              v-model="form.active"
              color="primary"
              :label="form.active ? 'Active' : 'Inactive'"
            ></v-switch>
          </v-col>
        </v-row>

        <slot></slot>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useActivityRule, useTranslatable } from '@/composables'
import merchantStore from '@/modules/merchant/store'
import useQuotaRuleList from '@/modules/product/views/quota-rule/useQuotaRuleList'
import { between } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    cols: {
      type: Number,
      default: 6,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    product: {
      // default resource
      type: Object,
      required: true,
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const getDefaultStock = () => {
      switch (props.product.nature) {
        case 'voucher':
          return 0
        case 'plan':
          return 0
        default:
          return 1000
      }
    }

    const initialForm = {
      name: useTranslatable(props.resource, 'name'),
      active: props.resource ? props.resource.active : true,
      merchant_sku_id: props.resource ? props.resource.merchant_sku_id : '',
      branch_id: props.resource ? props.resource.branch_id : null,
      quota_rule_id: props.resource ? props.resource.quota_rule_id : null,
      activity_rule_id: props.resource ? props.resource.activity_rule_id : null,
      stock: props.resource ? props.resource.stock : getDefaultStock(),
      stock_threshold: props.resource ? props.resource.stock_threshold : 100,
    }

    const form = ref({ ...initialForm })

    const branchOptions = computed(() => merchantStore.state.branches.map(branch => ({ title: branch.name.en, value: branch.id })))

    const formElem = ref(null)

    const validate = () => {
      console.log({ ...formElem.value })
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const { searchQuery, quotaRuleOptions } = useQuotaRuleList()
    const { activityRuleOptions, fetchActivityRules } = useActivityRule()
    fetchActivityRules({ take: 999 })

    if (props.resource?.quota_rule) {
      setTimeout(() => {
        searchQuery.value = props.resource.quota_rule.rechargeable.name.en
      }, 30)
    }

    return {
      form,
      formElem,
      validate,
      between,

      searchQuery,
      quotaRuleOptions,
      branchOptions,
      activityRuleOptions,
    }
  },
}
</script>
