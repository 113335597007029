<template>
  <v-form>
    <v-row>
      <v-col :cols="cols">
        <v-autocomplete
          v-model="form.sync_ids"
          placeholder="Select 0 or more languages"
          :items="languagesOptions"
          item-text="title"
          item-value="value"
          label="Language"
          outlined
          chips
          multiple
          hide-details
          @change="handleSync"
        ></v-autocomplete>
        <!-- <v-text-field
              v-model="searchText"
              placeholder="Search Language key, name"
            /> -->
      </v-col>
    </v-row>

    <v-alert
      v-for="msg in errors"
      :key="msg"
      color="warning"
    >
      {{ msg }}
    </v-alert>
  </v-form>
</template>

<script>
import commonStore from '@/modules/common/store'
import store from '@/store'
import axios from '@axios'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /admin/products/1/sync-languages
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    if (!commonStore.state.languages.length) {
      store.dispatch('common/fetchAllLanguages')
    }

    const searchText = ref('')

    const matchText = language => {
      const q = searchText.value.toLowerCase()
      const name = JSON.stringify(language.name).toLowerCase()

      return name.includes(q) || props.selectedIds.includes(language.id)
    }

    const languagesOptions = computed(() => commonStore.state.languages.filter(matchText).map(language => ({ title: language.name.en, value: language.id })))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
        emit('change')
      })
    }

    return {
      form,
      searchText,

      languagesOptions,
      handleSync,
    }
  },
}
</script>
