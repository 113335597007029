import { useExtractErrors } from '@/composables'
import { storeSku, storeSkuBoilerplate, updateSku } from '@api/product'
import { ref } from '@vue/composition-api'

export default function useSkuForm() {
  const skuErrors = ref([])
  const loading = ref(false)
  const skuData = ref(null)

  const storeSkuForm = (productId, form) => {
    const data = { ...form }
    data.product_id = productId

    loading.value = true
    skuErrors.value = []

    return storeSku(data)
      .then(res => {
        skuData.value = res.data.data

        return res
      })
      .catch(err => {
        skuErrors.value = useExtractErrors(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const makeSkuBoilerplate = skuId => {
    loading.value = true
    skuErrors.value = []

    return storeSkuBoilerplate(skuId)
      .catch(err => {
        skuErrors.value = useExtractErrors(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateSkuForm = (skuId, form) => {
    loading.value = true

    return updateSku(skuId, { ...form })
      .then(res => {
        skuData.value = res.data.data

        return res
      })
      .catch(console.log)
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,
    skuErrors,
    skuData,
    storeSkuForm,
    makeSkuBoilerplate,
    updateSkuForm,
  }
}
