<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="480"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <div
      v-if="productData && skuData"
      class="mb-5 mt-2"
    >
      <h3>{{ skuData.name.en }}</h3>
      <p>product: {{ productData.title.en }}</p>
    </div>

    <sku-form
      v-if="productData && skuData"
      mode="create"
      :product="productData"
      :cols="12"
      :resource="skuData"
      :loading="loading"
      :errors="skuErrors"
      :disabled-fields="[ ['voucher', 'plan'].includes(productData.nature) ? 'stock' : '' ]"
      @submit="handleSubmit"
    >
      <sync-language-form
        v-if="skuData && formReady"
        :cols="12"
        :selected-ids="skuData.languages.map(c => c.id)"
        :sync-base-path="`/admin/skus/${skuData.id}/languages/sync`"
        :sync-able="syncAble"
        class="mb-5"
        @change="refresh"
      />

      <sync-location-form
        v-if="skuData && formReady && product.nature === 'topup'"
        :cols="12"
        :selected-ids="skuData.branches.map(c => c.id)"
        :sync-base-path="`/admin/skus/${skuData.id}/branches/sync`"
        :sync-able="syncAble"
        class="mb-5"
        @change="refresh"
      />
      Available means Selectable branches in Quota Application. Ignore if not topup.
    </sku-form>
  </v-navigation-drawer>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import SyncLanguageForm from '@/modules/common/views/language-resource/SyncLanguageForm.vue'
import SyncLocationForm from '@/modules/merchant/views/branch-resource/SyncLocationForm.vue'
import { } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import useProduct from '../useProduct'
import SkuForm from './SkuForm.vue'
import useSkuForm from './useSkuForm'

export default {
  components: { SkuForm, SyncLanguageForm, SyncLocationForm },
  model: {
    prop: 'active',
    event: 'aside',
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },
    skuId: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { loadProduct, productData } = useProduct()
    const { updateSkuForm, skuErrors } = useSkuForm()

    const { product, skuId } = props

    const loading = ref(false)
    const syncAble = ref(false)
    const skuData = ref(null)
    const formReady = ref(true)

    const handleSubmit = async form => updateSkuForm(skuId, form).then(res => {
      console.log('form submit', form, res)

      // router.push({
      //   name: 'product-view',
      //   params: { id: productId },
      //   query: { tab: 'SKUS' },
      // })
      syncAble.value = true
      setTimeout(() => {
        useNotifySuccess({ content: 'SKU Update Succeeded.' })

        emit('changed', res.data.data)
      }, 100)
    })

    const refresh = () => {
      loadProduct(product.id).then(res => {
        skuData.value = res.data.data.skus.find(s => s.id === parseInt(skuId))
        formReady.value = false
        setTimeout(() => (formReady.value = true))
      })
    }

    onMounted(() => refresh())

    return {
      productData,
      skuData,
      refresh,
      formReady,

      loading,
      syncAble,
      handleSubmit,
      skuErrors,
    }
  },
}
</script>
