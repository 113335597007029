import { fetchQuotaRules } from '@/api/product'
import { useTableOptions } from '@/composables'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useUsersList() {
  const quotaRuleListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Expiry', value: 'interval_count' },
    { text: 'Quota', value: 'quota' },
    { text: 'Topup SKU', value: 'rechargeable_id' },
    { text: 'Apply Rule', value: 'application_rule', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  const quotaRuleOptions = computed(() =>
    quotaRuleListTable.value.map(qr => ({
      title: `${qr.rechargeable?.name.en} (${qr.quota})`,
      value: qr.id,
    })),
  )

  // fetch data
  const loadQuotaRules = () =>
    fetchQuotaRules(
      useTableOptions(options.value, {
        search: searchQuery.value,

        // root_only: 1,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        quotaRuleListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      loadQuotaRules()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    quotaRuleListTable,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadQuotaRules,
    quotaRuleOptions,
  }
}
